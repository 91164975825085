/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom styles
.footer {
  @apply bg-footerbgimg;
}
.custom-sets {
  @apply bg-customsetsbg;
}
.multi-img {
  @apply bg-multiimgbg;
}
.mission {
  @apply bg-mission;
}
.our-studio {
  @apply bg-ourstudio;
} */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* auto rotate */

.textcontainer {
  position: relative;
  text-align: center;
  bottom:120px;
  width: 100%;
  height: 240px; 
  /* padding-top : 280px; */
  overflow: hidden;
}

.sliding-text {
  position: absolute;
  /* right: 10px; */
  top: 30%; /* Start below the visible area */
  transform: translateY(100%);
  color: rgb(255, 255, 255); /* Change to your preferred text color */
  padding: 20px;
  box-sizing: border-box;
  font-size: 2em; /* Adjust as necessary */
  font-weight: bold;
  transition: transform 1s ease-in-out; /* Smooth sliding transition */
}

.sliding-text.active {
  transform: translateY(0%); /* Slide up into view */
}
/* auto rotate end */


/* marquee effect */
  .marquee-container {
    animation: reveal 2s ease-in-out forwards;
  }

  @keyframes reveal {
    0% {
      opacity: 0;
      transform: scale(0.8);
    }
    50% {
      opacity: 0.5;
      transform: scale(1.05);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }



  /* list dots  */
.custom-list {
  list-style-type: none; /* Remove default bullets */
}

.custom-list li::before {
  content: "•"; /* Customize the bullet character */
  color: rgb(255, 255, 255); /* Bullet color */
  font-size: 1em; /* Bullet size */
  display: inline-block; 
  width: 1em; /* Bullet spacing */
  margin-right: 0.em; /* Space between bullet and text */
}




